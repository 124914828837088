import React, { useState, useEffect } from "react";
import Loading from "./Loading.js";
import axios from "axios";
import Symbol from "./Symbol.js";
const Topbar = ({ font, setFont }) => {
  const [weather, setWeather] = useState(null);
  const [expand, setExpand] = useState(false);
  const lang = require(`../languages/${font}.json`);
  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(true);

  const cities = [
    "Tehran",
    "Mashhad",
    "Isfahan",
    "London",
    "Madrid",
    "Berlin",
    "Moscow",
    "NYC",
  ];
  const apiKey = "N2HZUSDSS2UTDNTNSEFJDEGNN";
  const temp = [
    { address: "Tehran", currentConditions: { temp: 32 } },
    { address: "Tabriz", currentConditions: { temp: 24 } },
    { address: "Isfahan", currentConditions: { temp: 38 } },
    { address: "Kerman", currentConditions: { temp: 40 } },
    { address: "Yazd", currentConditions: { temp: 41 } },
    { address: "Mashhad", currentConditions: { temp: 35 } },
    { address: "Karaj", currentConditions: { temp: 30 } },
  ];
  useEffect(() => {
    // const fetchWeatherData = async () => {
    //   try {
    //     const requests = cities.map((city) =>
    //       axios.get(
    //         `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${city}?unitGroup=us&key=${apiKey}&contentType=json`
    //       )
    //     );
    //     const responses = await Promise.all(requests);
    //     const data = responses.map((response) => response.data);
    //     setWeather(data);
    //   } catch (err) {
    //     setWeather(temp);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // fetchWeatherData();
    setWeather(temp);
    setLoading(false);
  });

  if (loading) return <Loading font={font} />;
  return (
    <div className="w-full h-fit flex flex-row py-5 md:px-16 lg:px-20 px-5 ">
      <div
        className="w-fit h-fit flex flex-row gap-x-2 "
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <span className="text-white  font-bold">{lang["sina"]}</span>
        <Symbol
          hovered={hovered}
          width={28}
          height={28}
          top={7}
          mar={0}
          color={"white"}
          strokeSize={1}
          animation={false}
        />{" "}
      </div>
      <div className="flex-1 w-full h-full flex flex-row-reverse ">
        {/* language Toggle */}
        <button
          onClick={() => setExpand(!expand)}
          className="flex flex-col w-fit "
        >
          <div className="flex flex-row gap-x-2 mt-0.5 px-5">
            <span className="text-gray text-sm font-extrabold">
              {lang[font]}
            </span>
            <svg
              className={`fill-none mt-2 transform transition-transform duration-300 ${
                expand ? "rotate-180" : "rotate-0"
              }`}
              stroke="#808080"
              width={15}
              height={10}
            >
              <path d="M 1 1 L 7.8 6.5" strokeWidth={2} />
              <path d="M 7.8 6.5 L 14 1" strokeWidth={2} />
            </svg>
          </div>
          <div
            className={
              expand
                ? "bg-[#282C33] border-2 border-solid border-purple h-fit w-full flex flex-col rounded-2xl text-white gap-y-2 px-1  py-2 mt-2"
                : "bg-[#282C33] border-2 border-solid border-[#282C33] h-fit w-fit flex flex-col rounded-2xl text-[#282C33] gap-y-2 mt-2 px-3 py-2 hover:hidden"
            }
          >
            <button
              onClick={() => setFont("EN")}
              className="rounded-2xl text-sm font-bold font-sans hover:bg-white hover:text-black"
            >
              {lang["EN"]}
            </button>
            <button
              onClick={() => setFont("FA")}
              className="rounded-2xl text-sm font-bold font-sans hover:bg-white hover:text-black"
            >
              {lang["FA"]}
            </button>
          </div>
        </button>
        <div className="w-full h-fit text-white overflow-x-hidden flex flex-col justify-center items-center">
          <div className="flex-1 overflow-hidden w-full h-fit animate-marquee flex flex-row gap-x-5 ">
            {weather &&
              weather.map((data) => (
                <div className="flex flex-row w-fit h-fit items-center justify-center">
                  <span className="">{data.address}</span>
                  <span> : </span>
                  <span className="">{data.currentConditions.temp}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
