import React from "react";
import Telegram from "../images/Telegram.png";
import Email from "../images/Email.png";

const Contacts = ({ font }) => {
  const lang = require(`../languages/${font}.json`);
  return (
    <div
      dir={font === "EN" ? "ltr" : "rtl"}
      className="w-full h-full flex flex-col gap-y-10 mt-10"
    >
      <div className="w-full h-fit flex flex-row gap-x-2 text-2xl ">
        <span className="text-purple">#</span>
        <span className="text-white">{lang["contacts"]}</span>
        <hr className="text-purple flex-1 w-full h-full flex mt-4" />
      </div>
      <div className="flex-1 w-full h-full flex flex-col md:flex-row gap-y-10">
        <span className="flex text-gray w-fit max-w-lg h-full gap-x-10 px-5">
          {lang["contactsContext"]}
        </span>
        <div className=" flex justify-center md:justify-end items-start w-full h-full">
          <div
            dir="ltr"
            className="border-2 border-solid border-gray w-fit h-fit p-5 text-gray"
          >
            <div className="flex flex-row gap-x-2 px-2">
              <img alt="" src={Telegram} />
              <span>@Strix_Strigidae</span>
            </div>
            <div className="flex flex-row gap-x-2 px-2">
              <img alt="" src={Email} />
              <span>sinabook20031381@gmail.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
