import React from "react";

const Symbol = ({
  width,
  height,
  strokeSize,
  color,
  top,
  animation,
  hovered,
}) => {
  const thirtySevenHalf = (37.5 / 200) * width;
  const seventyFive = (75 / 200) * width;
  const oneFifty = (150 / 200) * width;
  const onetwelveHalf = (112.5 / 200) * width;

  return (
    <div className="flex flex-row w-fit">
      <svg
        className={`fill-none mt-1 w-fit p-0 ${top === 52 ? "mr-2" : "mr-0"} ${
          hovered ? "-translate-x-20" : ""
        } transition-all duration-700`}
        stroke={color}
        width={width}
        height={height}
        strokeWidth={strokeSize}
      >
        {/* Left Shape */}
        <path d={`M 0 ${thirtySevenHalf} L 0 ${oneFifty}`} />
        <path
          d={`M 0 ${thirtySevenHalf} L ${seventyFive} ${thirtySevenHalf}`}
        />
        <path
          d={`M ${seventyFive} ${thirtySevenHalf} L ${seventyFive} ${seventyFive}`}
        />
        <path
          d={`M ${seventyFive} ${seventyFive} L ${thirtySevenHalf} ${seventyFive}`}
        />
        <path
          d={`M ${thirtySevenHalf} ${seventyFive} L ${thirtySevenHalf} ${onetwelveHalf}`}
        />
        <path
          d={`M ${thirtySevenHalf} ${onetwelveHalf} L ${seventyFive} ${onetwelveHalf}`}
        />
        <path
          d={`M ${seventyFive} ${onetwelveHalf} L ${seventyFive} ${oneFifty}`}
        />
        <path d={`M ${seventyFive} ${oneFifty} L 0 ${oneFifty}`} />
      </svg>

      <svg
        className={`fill-none mt-1 w-fit p-0 relative ${
          top === 52 ? "right-44" : "right-6"
        } z-20 ${animation ? "animate-moveX" : ""} `}
        stroke={color}
        width={width}
        height={height}
        strokeWidth={strokeSize}
      >
        <path
          d={`M ${thirtySevenHalf} 0 L ${thirtySevenHalf} ${thirtySevenHalf}`}
        />
        <path
          d={`M ${thirtySevenHalf} ${thirtySevenHalf} L ${seventyFive} ${thirtySevenHalf}`}
        />
        <path
          d={`M ${seventyFive} ${thirtySevenHalf} L ${seventyFive} ${seventyFive}`}
        />
        <path
          d={`M ${seventyFive} ${seventyFive} L ${thirtySevenHalf} ${seventyFive}`}
        />
        <path
          d={`M ${thirtySevenHalf} ${seventyFive} L ${thirtySevenHalf} ${onetwelveHalf}`}
        />
        <path
          d={`M ${thirtySevenHalf} ${onetwelveHalf} L ${onetwelveHalf} ${onetwelveHalf}`}
        />
        <path d={`M ${onetwelveHalf} ${onetwelveHalf} L ${onetwelveHalf} 0`} />
        <path d={`M ${thirtySevenHalf} 0 L ${onetwelveHalf} 0`} />
      </svg>
    </div>
  );
};

export default Symbol;
